<template>
    <div class="missao mt-10">
        <br />
            <br />
        <Loading :active="loading" is-full-screen />
        <HomeRemote :remote="remote" />
        <div class="cards" v-if="textSelect.title">
            <div class="flex justify-center items-center" v-if="!textSelect.varios"><img class="igg"
                    :src="getSrc(textSelect)" @error="getImage" /></div>
            <div>
                <div>
                    <div style="margin-top: 10px">
                        <strong>{{ type === 'pokemon' && !textSelect.varios ? `#${textSelect.value} - ` : '' }}
                            {{
                                $i18n.locale === 'pt-br'
                                ? textSelect.title.texto_pt || textSelect.title.texto
                                : $i18n.locale === 'es-es'
                                    ? textSelect.title.texto_es || textSelect.title.texto
                                    : textSelect.title.texto_en || textSelect.title.texto
                            }}</strong>
                    </div>
                </div>
                <div v-if="textSelect.missao.length && !textSelect.varios">
                    {{
                        $i18n.locale === 'pt-br'
                        ? textSelect.missao[0].texto_pt
                        : $i18n.locale === 'es-es'
                            ? textSelect.missao[0].texto_es
                            : textSelect.missao[0].texto_en
                    }}
                </div>
                <div v-if="!textSelect.varios" class="flex justify-center items-center">
                    <img class="flag" :src="flag[textSelect.location]" style="margin-right: 5px" />
                    {{
                        $i18n.locale === 'pt-br'
                        ? getCity(textSelect.location).title_pt
                        : $i18n.locale === 'es-es'
                            ? getCity(textSelect.location).title_es
                            : getCity(textSelect.location).title
                    }}
                </div>

                <div v-if="!textSelect.varios">
                    <div>{{ getCity(textSelect.location).date }}</div>
                </div>

                <!-- {{ textSelect }} -->
                <div>
                    {{ $t('missao.total') }}: {{ result.length }} | Sets:
                    {{ Math.round(result.length / 3) }}
                </div>
            </div>
        </div>

       <!--  <div class="btn btn-velocity" href="#" @click.prevent="selectVelocity">
            {{
                velocity
                ? $t('missao.velocityAtivado')
                : $t('missao.velocityDesativado')
            }}
        </div> -->
        <ul class="home">
            <li v-for="(r, k) in result" :key="k">
                <div v-if="k % 3 === 0">
                    <strong>Set {{ k / 3 + page * 7 + 1 }}</strong>
                </div>
                <table>
                    <tr>
                        <td class="coord">
                            <a @click.prevent="getCopy(r, r.id)" href="#">
                            <span class="coord" :style="r.copy ? 'text-decoration: line-through;' : ''">{{ r.coord
                            }}</span></a>
                        </td>
                        <td class="tempo">
                            <span v-if="!r.copy">{{ r.tempo }} </span>
                            <span class="text-tempo" v-else>
                                <countdown :time="r.time * 1000" :transform="transform">
                                    <template slot-scope="props">
                                        {{ props.minutes }}:{{ props.seconds }}</template>
                                </countdown>
                            </span>
                        </td>
                        <td class="copy" v-if="!velocity">
                            <!-- <td class="copy" v-if="!velocity"> -->
                            <vue-flip active-click width="80px" height="40px" class="btn-content" v-model="r.copy">
                                <template v-slot:front>
                                    <div class="btn" @click="getCopy(r, r.id)">
                                        {{ $t('missao.copiar') }}
                                    </div>
                                </template>
                                <template v-slot:back>
                                    <div class="btn" @click="getCopy(r, r.id)">=)</div>
                                </template>
                            </vue-flip>
                        </td>
                        <td v-else>
                            <!--  <td v-else> -->
                            <vue-flip active-click width="100px" height="45px" class="btn-content" v-model="r.copy">
                                <template v-slot:front>
                                    <div class="btn" @click="getCopyVelocity(r, r.id)">
                                        {{ $t('missao.Abrir') }}
                                    </div>
                                </template>
                                <template v-slot:back>
                                    <div class="btn" @click="getCopyVelocity(r, r.id)">=)</div>
                                </template>
                            </vue-flip>
                        </td>
                    </tr>
                </table>

                <hr v-if="k % 3 === 2" />
            </li>
        </ul>
    </div>
</template>

<script>
import axios from 'axios'
import VueFlip from 'vue-flip'
import Countdown from '@chenfengyuan/vue-countdown'
import '@fortawesome/fontawesome-free/css/all.css'
import 'vue-ads-pagination/dist/vue-ads-pagination.css'
import City from '../components/City'
import { Haptics, ImpactStyle } from '@capacitor/haptics'
import HomeRemote from '../components/HomeRemote.vue'
import Loading from '../components/Loading'

export default {
    name: 'App',
    props: ['remote'],
    components: {
        Countdown,
        VueFlip,
        HomeRemote,
        Loading
    },
    data() {
        return {
            text: '',
            result: '',
            results: [],
            list: [],
            select: '',
            loading: false,
            page: 0,
            total: 0,
            perpage: 21,
            active: false,
            activeMapa: false,
            gpx: '',
            textSelect: {},
            flag: {
                us: require('../assets/bandeiras/usa.png'),
                ca: require('../assets/bandeiras/can.png'),
                gb: require('../assets/bandeiras/ing.png'),
                sg: require('../assets/bandeiras/sin.png'),
                au: require('../assets/bandeiras/aus.png'),
            },
            saida: '',
            botao: false,
            velocity: false,
            type: '',
            end: false,
            lcl: 'us',
        }
    },
    async mounted() {
      this.loading = true
        document.title = `${this.$route.meta.lang} CoordsGo.com - ${this.$route.meta.titulo}`
        this.end = false
        const result = await axios.get(`${this.$url}/quest/simples?id=${this.$route.query.id}`).catch(() => {
          this.loading = false
        })
        this.result = result.data
        /* if (localStorage.getItem('_result')) {
            this.active = true
            this.page = +localStorage.getItem('_page') || 0
            this.textSelect = JSON.parse(localStorage.getItem('_header'))
            this.result = JSON.parse(localStorage.getItem('_result')).map(i => {
                if (i.copy) {
                    i.time = 0
                }
                return i
            })
        } */
        this.loading = false
        if (localStorage.getItem('_velocity')?.length) {
            this.velocity =
                localStorage.getItem('_velocity') === 'true' ? true : false
        } else {
            this.velocity = false
        }
        //this.getListCoords(1)
    },
    methods: {
        setCity(item) {
            //this.loading = true
            this.lcl = item.slug
            this.$refs.missao.setCity(item)
        },
        getEnd(e) {
            this.end = e
        },
        getCity(city) {
            return City.filter(i => i.slug === city)[0]
        },
        setType(e) {
            this.type = e
        },
        leftPad(value, totalWidth, paddingChar) {
            var length = totalWidth - value.toString().length + 1
            return Array(length).join(paddingChar || '0') + value
        },
        getSrc(i) {
            try {
                if (this.type === 'stardust') {
                    return require('../assets/item/stardust_painted.png')
                } else if (this.type === 'itens') {
                    let is
                    const url = 'https://images.weserv.nl/?dpr=1&w=200&il&url=https://cdn.jsdelivr.net/gh/PokeMiners/pogo_assets/Images/Items/'
                    if (i.value === '1') {
                        is = 'pokeball_sprite.png'
                        return url + is
                    } else if (i.value === '2') {
                        is = 'greatball_sprite.png'
                        return url + is
                    } else if (i.value === '3') {
                        is = 'ultraball_sprite.png'
                        return url + is
                    } else if (i.value2) {
                        return i.value2
                    } else {
                        is = `Item_${this.leftPad(i.value, 4)}.png`
                        return url + is
                    }
                } else if (this.type === 'mega') {
                    let m
                    if (i.value2) {
                        return i.value2
                    } else {
                        m = `${this.leftPad(i.value, 3)}_51`
                        const url = 'https://images.weserv.nl/?dpr=1&w=200&il&url=https://cdn.jsdelivr.net/gh/PokeMiners/pogo_assets/Images/Pokemon/'
                        return `${url}pokemon_icon_${m}.png`
                    }
                } else {
                    let v
                    if (i.value2) {
                        v = `${this.leftPad(i.value2, 3)}_00`
                    } else if (i.value5) {
                        v = `${this.leftPad(i.value, 3)}_${i.value5}`
                    } else
                        if (i.value) {
                            let nn
                            if (i.title.texto.match(/Alola/)) {
                                nn = '.fALOLA'
                            } else if (i.title.texto.match(/Outono/)) {
                                nn = '.fAUTUMN'
                            } else if (i.title.texto.match(/Primavera/)) {
                                nn = '.fSPRING'
                            } else if (i.title.texto.match(/Verão/)) {
                                nn = '.fSUMMER'
                            } else if (i.title.texto.match(/Inverno/)) {
                                nn = '.fWINTER'
                            }
                            else if (i.title.texto.match(/Galarian/)) {
                                nn = '.fGALARIAN'
                            } else {
                                if (i.value == 327) {
                                    nn = '.f00'
                                } else if (i.value == 412) {
                                    nn = '.fBURMY_PLANT'
                                }
                                else if (i.value == 412) {
                                    nn = '.fBURMY_PLANT'
                                }
                                else {
                                    nn = ''
                                }
                            }
                            v = i.value + nn
                        }
                    const url = 'https://images.weserv.nl/?dpr=1&w=200&il&url=https://cdn.jsdelivr.net/gh/PokeMiners/pogo_assets/Images/Pokemon/Addressable%20Assets/'
                    if (!i.value4) {
                        return `${url}pm${v}${i.shiny ? '.s' : ''}.icon.png`
                    } else {
                        return i.value4
                    }
                }
            } catch (e) {
                return require('../assets/pokemon/0.png')
            }
        },
        selectVelocity() {
            this.velocity = !this.velocity
        },
        getCopyVelocity(r) {
            if (!r.copy) {
                window.open(`https://pk.md/${r.coord}`, '_blank')
            }
        },
        getListUpdate() {
            this.page = 0
            this.getListCoords(1)
        },
        async getListCoords(page) {
            this.loading = true
            const list = await axios(`${this.$url}/missoes`, {
                params: {
                    page
                }
            })
            this.list = list.data.data
            this.total = list.data.total
            this.loading = false
        },
        setSaida(e) {
            this.saida = e
        },
        setBotao(e) {
            this.botao = e
        },
        copy3set(e) {
            this.$copyText(e).then(
                () => null,
                () => alert('Erro')
            )
        },
        setSaidaCopy() {
            this.$copyText(this.saida).then(
                () => null,
                () => alert('Erro')
            )
        },
        pageChange(page) {
            this.page = page.page
            this.getListCoords(page.page + 1)
            localStorage.setItem('_page', page.page)
            document.body.scrollIntoView({ behavior: 'smooth', block: 'start' })
        },

        getCopy(r) {
            if (!r.copy) {
                this.$copyText(r.coord).then(
                    () => null,
                    () => alert('Erro')
                )
            }
        },
        transform(props) {
            Object.entries(props).forEach(([key, value]) => {
                const digits = value < 10 ? `0${value}` : value
                props[key] = `${digits}`
            })

            return props
        },
        convert(num, text) {
            switch (text) {
                case 'seg':
                    return num
                case 'sec':
                    return num
                case 'min':
                    return num * 60
                default:
                    return num
            }
        },
        async getListCoordsRouter(id) {
            const list = await axios.get(`${this.$url}/missoes/${id}`)
            this.setListCoordList(list.data)
        },
        async setListCoordList(list) {
            this.active = true
            this.getList(list.content)
            this.select = list.id
            await axios.put(`${this.$url}/missoes/${this.select}`, {
                click: Number(list.click) + 1
            })
            this.gpx = ''
            this.activeMapa = false
            document.body.scrollIntoView({ behavior: 'smooth', block: 'start' })
        },
        getImage(e) {
            e.target.src = require('../assets/pokemon/0.png')
        },
        async setListCoord() {
            //this.$router.push(`/coordinates/${list.id}`)
            this.active = true
            document.body.scrollIntoView({ behavior: 'smooth', block: 'start' })
            /*  this.getList(list.content)
            this.select = list.id
            await axios.put(
              `${this.$url}/missoes/${this.select}`,
              {
                click: Number(list.click) + 1
              }
            ) */
            //this.gpx = ''
            //this.activeMapa = false
            //document.body.scrollIntoView({ behavior: 'smooth', block: 'start' })
        },
        async setListConvert() {
            this.select = ''
            this.gpx = ''
            this.activeMapa = false
            await axios.post(`${this.$url}/missoesold`, {
                text: this.text
            })
            this.getList(this.text)
        },
        async hapticsImpactMedium() {
            await Haptics.impact({ style: ImpactStyle.Medium })
        }
    }
}
</script>

<style lang="scss">
.missao {
    margin-top: 10px;
}

ul.home {
    margin: 0;
    padding: 0;
}

.home {
  strong {
    font-size: 14px;
  }
}

li {
    list-style: none;
    line-height: 30px;
}

li a {
    color: #2c3e50;
    text-decoration: none;
}

.coord {
    width: 200px;
    font-size: 12px;
}

textarea {
    width: 100%;
    margin: 10px auto;
    border: 2px solid #673bb7;
    padding: 10px;
    box-sizing: border-box;
}

table {
    margin: 0 auto;
    width: 100%;
}

td {
    text-align: left;
}

td:nth-child(2) {
    width: 70px;
}

hr {
    border-bottom: 1px dashed #ccc;
    border-style: dashed;
    width: 300px;
}

.list-corpo {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

button {
    font-size: 18px;
    padding: 5px;
    margin: 0 5px;
}

.whats {
    margin-top: 4px;
}

.back {
    background-color: #ffc107;
}

.front {
    background-color: #673ab7;
}

.back,
.front {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    width: 100%;
    height: 100%;
}

.back {
    transform: rotateY(180deg);
}

.back,
.front {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
}

.back,
.flipper,
.front {
    transform-style: preserve-3d;
    border-radius: 5px;
}

.btn-content {
    margin: 0 auto;
}

.btn-container {
    flex: 1;
    margin: 0 auto 15px auto;
}

.btn {
    display: flex;
    height: 50px;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.tempo {
  span {
    font-size: 12px;
  }
}

.text-tempo {
    background-color: #673bb7;
    color: #fff;
    font-size: 12px;
    padding: 4px;
}

.btn-down {
    background: #673bb7;
    color: #fff;
    text-decoration: none;
    height: 50px;
    flex: 1;
    border-radius: 5px;
}

.btn-yellow {
    background-color: #ffc107;
    color: #fff;
    text-decoration: none;
    height: 50px;
    flex: 1;
    border-radius: 5px;
}

.btn-container {
    flex: 1;
    margin: 0 3px 15px 3px;
}

.list {
    padding: 5px;
    box-sizing: border-box;
    text-align: initial;
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
    overflow: hidden;
    //text-overflow: ellipsis;
    //white-space: nowrap;
    flex: 1;
}

.list small {
    font-size: 12px;
    font-weight: normal;
}

.date {
    font-size: 10px !important;
}

.list.active {
    background: rgb(103 59 183 / 21%);
}

.list-content {
    cursor: pointer;
    font-weight: normal;
    font-size: 13px;
}

.list-content .btn-down {
    width: 120px;
    height: 35px;
    border-radius: 5px;
}

h2 {
    display: flex;
    justify-content: space-between;
    color: #673bb7;
}

.vue-ads-leading-loose {
    display: none !important;
}

.vue-ads-justify-end {
    justify-content: flex-start !important;
}

.vue-ads-bg-teal-500 {
    background-color: #673bb7 !important;
}

.vue-ads-flex {
    width: 100% !important;
    box-sizing: border-box !important;
    padding: 0 !important;
    margin: 10px 0 !important;
}

.vue-ads-flex-grow {
    flex-grow: 0 !important;
    flex: 1 !important;
    justify-content: space-between !important;
}

.vue-ads-text-xs {
    font-size: 1rem !important;
}

.yellow {
    background-color: #ffc107 !important;
}

.logo {
    background: #673bb7;
    width: 40px;
    height: 40px;
    font-size: 32px;
    color: #fff;
    margin-left: 15px;
}

.logo .c {
    position: relative;
    top: -12px;
    left: -4px;
}
</style>

<style lang="scss" scoped>
.btn-copy {
    color: #fff;
    background: #673ab7;
    width: 110px !important;
    margin: 0 auto;
    text-align: center;
    height: 25px !important;
    font-size: 12px;
}

.btn-velocity {
    color: #fff;
    background: #673ab7;
    width: 130px !important;
    margin: 0 auto;
    text-align: center;
    height: 25px !important;
    font-size: 12px;
    margin-bottom: 20px;
    border-radius: 5px;
}

.cards {
    border-radius: 5px;
    font-size: 14px;
}

.flag {
    height: 30px;
}

.igg {
    max-height: 60px;
    max-width: 60px;
}
</style>
