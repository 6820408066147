import moment from 'moment'
import 'moment-timezone'

export default [
  {
    title: "Honolulu",
    title_pt: "Honolulu",
    title_es: "Osaka",
    slug: "hon",
    img: require("../assets/bandeiras/usa.png"),
    timezone: "Pacific/Honolulu",
    date: moment()
      .tz("Pacific/Honolulu")
      .format("HH:mm:ss ‎ ‏‏‎‎ ‏‏‎‎ ‏‏‎‎ ‏‏‎‎DD/MM/YY"),
    status: false,
    select: false,
    raid: true,
    new: false
    //breve: true
  },
  {
    title: "Vancouver",
    title_pt: "Vancouver",
    title_es: "Vancouver",
    slug: "ca",
    img: require("../assets/bandeiras/can.png"),
    timezone: "America/Vancouver",
    date: moment()
      .tz("America/Vancouver")
      .format("HH:mm:ss ‎ ‏‏‎‎ ‏‏‎‎ ‏‏‎‎ ‏‏‎‎DD/MM/YY"),
    status: true,
    raid: true,
    select: false
  },
  {
    title: "Los Angeles",
    title_pt: "Los Angeles",
    title_es: "Osaka",
    slug: "la",
    img: require("../assets/bandeiras/usa.png"),
    timezone: "America/Los_Angeles",
    date: moment()
      .tz("America/Los_Angeles")
      .format("HH:mm:ss ‎ ‏‏‎‎ ‏‏‎‎ ‏‏‎‎ ‏‏‎‎DD/MM/YY"),
    status: false,
    select: false,
    raid: true,
    new: false
    //breve: true
  },
  {
    title: "Pier 39",
    title_pt: "Pier 39",
    title_es: "Osaka",
    slug: "pier",
    img: require("../assets/bandeiras/usa.png"),
    timezone: "America/Los_Angeles",
    date: moment()
      .tz("America/Los_Angeles")
      .format("HH:mm:ss ‎ ‏‏‎‎ ‏‏‎‎ ‏‏‎‎ ‏‏‎‎DD/MM/YY"),
    status: false,
    select: false,
    raid: true,
    new: false
    //breve: true
  },
  {
    title: "New York",
    title_pt: "Nova York",
    title_es: "Nueva York",
    slug: "us",
    img: require("../assets/bandeiras/usa.png"),
    timezone: "America/New_York",
    date: moment()
      .tz("America/New_York")
      .format("HH:mm:ss ‎ ‏‏‎‎ ‏‏‎‎ ‏‏‎‎ ‏‏‎‎DD/MM/YY"),
    status: true,
    raid: true,
    select: true
  },
  {
    title: "London",
    title_pt: "Londres",
    title_es: "Londres",
    slug: "gb",
    img: require("../assets/bandeiras/ing.png"),
    timezone: "Europe/London",
    date: moment()
      .tz("Europe/London")
      .format("HH:mm:ss ‎ ‏‏‎‎ ‏‏‎‎ ‏‏‎‎ ‏‏‎‎DD/MM/YY"),
    status: true,
    raid: true,
    select: false
  },
  {
    title: "Zaragoza",
    title_pt: "Zaragoza",
    title_es: "Zaragoza",
    slug: "esp",
    img: require("../assets/bandeiras/espana.png"),
    timezone: "Europe/Madrid",
    date: moment()
      .tz("Europe/Madrid")
      .format("HH:mm:ss ‎ ‏‏‎‎ ‏‏‎‎ ‏‏‎‎ ‏‏‎‎DD/MM/YY"),
    status: false,
    select: false,
    raid: true,
    new: false
    //breve: true
  },
  {
    title: "Singapur",
    title_pt: "Singapura",
    title_es: "Singapur",
    slug: "sg",
    img: require("../assets/bandeiras/sin.png"),
    timezone: "Asia/Singapore",
    date: moment()
      .tz("Asia/Singapore")
      .format("HH:mm:ss ‎ ‏‏‎‎ ‏‏‎‎ ‏‏‎‎ ‏‏‎‎DD/MM/YY"),
    status: true,
    raid: true,
    select: false
  },
  /* {
      title: 'Taipei',
      title_pt: 'Taipei',
      title_es: 'Taipéi',
      slug: 'tw',
      img: require('../assets/flags/small/asia/bur.png'),
      timezone: 'Asia/Taipei',
      date: moment()
        .tz('Asia/Taipei')
        .format('HH:mm:ss ‎ ‏‏‎‎ ‏‏‎‎ ‏‏‎‎ ‏‏‎‎DD/MM/YY'),
      status: true,
      select: false,
      new: false,
      breve: true
    }, */
  {
    title: "Sydney",
    title_pt: "Sydney",
    title_es: "Sydney",
    slug: "au",
    img: require("../assets/bandeiras/aus.png"),
    timezone: "Australia/Sydney",
    date: moment()
      .tz("Australia/Sydney")
      .format("HH:mm:ss ‎ ‏‏‎‎ ‏‏‎‎ ‏‏‎‎ ‏‏‎‎DD/MM/YY"),
    status: true,
    raid: true,
    select: false
  },
  {
    title: "Osaka",
    title_pt: "Osaka",
    title_es: "Osaka",
    slug: "jp",
    img: require("../assets/bandeiras/japon.png"),
    timezone: "Asia/Tokyo",
    date: moment()
      .tz("Asia/Tokyo")
      .format("HH:mm:ss ‎ ‏‏‎‎ ‏‏‎‎ ‏‏‎‎ ‏‏‎‎DD/MM/YY"),
    status: false,
    select: false,
    raid: true,
    new: false
    //breve: true
  },
  {
    title: "New Zealand",
    title_pt: "Nova Zelandia",
    title_es: "Zaragoza",
    slug: "nz",
    img: require("../assets/bandeiras/nueva-zelanda.png"),
    timezone: "Pacific/Auckland",
    date: moment()
      .tz("Pacific/Auckland")
      .format("HH:mm:ss ‎ ‏‏‎‎ ‏‏‎‎ ‏‏‎‎ ‏‏‎‎DD/MM/YY"),
    status: false,
    select: false,
    raid: true,
    new: false
    //breve: true
  }
]
