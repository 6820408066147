var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.remote.coordenadas_ativo)?_c('div',{staticClass:"topo"},[_c('h4',[_vm._v(_vm._s(_vm.remote.coordenadas.event_br))]),(_vm.remote.coordenadas.img)?_c('img',{staticClass:"img-hora",attrs:{"src":`${_vm.$url}/storage/${_vm.remote.coordenadas.img}`}}):_vm._e(),(_vm.remote.coordenadas.timer)?_c('div',{staticClass:"countdown"},[_c('div',{staticClass:"content-inicio"},[_c('div',{staticClass:"text"}),_c('div',{staticClass:"datee"},[_vm._v(_vm._s(_vm._f("formatDate")(_vm.remote.coordenadas.inicio)))])]),_c('VueCountdownTimer',{attrs:{"start-time":_vm.inicio,"end-time":_vm.fim,"interval":1000,"start-label":_vm.$t('eventos.inicia'),"end-label":_vm.$t('eventos.termina'),"label-position":"begin","end-text":_vm.$t('eventos.finalizado'),"day-txt":'d',"hour-txt":'h',"minutes-txt":'m',"seconds-txt":'s'},scopedSlots:_vm._u([{key:"start-label",fn:function(scope){return [(scope.props.startLabel !== '' &&
                    scope.props.tips &&
                    scope.props.labelPosition === 'begin'
                    )?_c('span',{staticStyle:{"color":"red"}},[_c('strong',[_vm._v(_vm._s(scope.props.startLabel)+":")])]):_vm._e(),(scope.props.endLabel !== '' &&
                    !scope.props.tips &&
                    scope.props.labelPosition === 'begin'
                    )?_c('strong',[_vm._v(_vm._s(scope.props.endLabel)+":")]):_vm._e()]}},{key:"countdown",fn:function(scope){return [_c('span',[_vm._v(_vm._s(scope.props.days))]),_vm._v(_vm._s(scope.props.dayTxt)+" "),_c('span',[_vm._v(_vm._s(scope.props.hours))]),_vm._v(_vm._s(scope.props.hourTxt)+" "),_c('span',[_vm._v(_vm._s(scope.props.minutes))]),_vm._v(_vm._s(scope.props.minutesTxt)+" "),_c('span',[_vm._v(_vm._s(scope.props.seconds))]),_vm._v(_vm._s(scope.props.secondsTxt)+" ")]}},{key:"end-label",fn:function(scope){return [(scope.props.startLabel !== '' &&
                    scope.props.tips &&
                    scope.props.labelPosition === 'end'
                    )?_c('span',[_c('strong',[_vm._v(_vm._s(scope.props.startLabel)+":")])]):_vm._e(),(scope.props.endLabel !== '' &&
                    !scope.props.tips &&
                    scope.props.labelPosition === 'end'
                    )?_c('span',[_c('strong',[_vm._v(_vm._s(scope.props.endLabel)+":")])]):_vm._e()]}},{key:"end-text",fn:function(scope){return [_c('span',[_c('strong',[_vm._v(_vm._s(scope.props.endText))])])]}}],null,false,2604220510)})],1):_vm._e(),(_vm.remote.coordenadas.coords_ativo)?_c('div',{staticClass:"list-group"},_vm._l((_vm.remote.coordenadas.coords),function(c,index){return _c('div',{key:index,staticClass:"list-group-item"},[_c('div',{staticClass:"coord-content"},[_c('div',{staticClass:"coord-texts"},[_c('div',{staticClass:"coord-text",style:(c.copy
                            ? 'text-decoration: line-through;'
                            : '')},[_vm._v(" "+_vm._s(c.coord)+" ")])]),_c('div',{staticClass:"coord-btn"},[_c('vue-flip',{staticClass:"btn-content",attrs:{"active-click":"","width":"100px","height":"45px"},scopedSlots:_vm._u([{key:"front",fn:function(){return [_c('div',{staticClass:"btn",on:{"click":function($event){return _vm.getCopy(c)}}},[_vm._v(" "+_vm._s(_vm.$t('locais.copiar'))+" ")])]},proxy:true},{key:"back",fn:function(){return [_c('div',{staticClass:"btn",on:{"click":function($event){return _vm.getCopy(c)}}},[_vm._v("=)")])]},proxy:true}],null,true),model:{value:(c.copy),callback:function ($$v) {_vm.$set(c, "copy", $$v)},expression:"c.copy"}})],1)])])}),0):_vm._e()]):_vm._e(),(_vm.remote.coordenadas.ads_ativo)?_c('div',[_c('a',{attrs:{"href":_vm.remote.coordenadas.url,"target":"_blank"}},[(_vm.remote.coordenadas.img2)?_c('img',{staticClass:"img-hora",attrs:{"src":`${_vm.$url}/storage/${_vm.remote.coordenadas.img2}`}}):_vm._e()])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }